import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
import allTypeScales from '../../../data/type-scales/all.json';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Font sizes`}</h1>
    <p>{`In the `}<a parentName="p" {...{
        "href": "https://system-ui.com/theme"
      }}>{`System UI Theme Specification`}</a>{` the
`}<inlineCode parentName="p">{`fontSizes`}</inlineCode>{` key is used to represent a type scale.`}</p>
    <p>{`To generate a type scale, a random scale is selected and combined with
a random number of steps and a base font size. The scale is returned in
`}<inlineCode parentName="p">{`rem`}</inlineCode>{`.`}</p>
    <p>{`Steps that are larger than `}<inlineCode parentName="p">{`10rem`}</inlineCode>{` or smaller than `}<inlineCode parentName="p">{`.6rem`}</inlineCode>{` are filtered
out since they don't make sense in a type scale. If values outside of that
range are needed they can be handled as one-offs.`}</p>
    <pre>{JSON.stringify(allTypeScales, null, 2)}</pre>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/theme/font-sizes"
        }}><inlineCode parentName="a">{`/theme/font-sizes`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/generate/font-sizes"
        }}><inlineCode parentName="a">{`/api/generate/font-sizes`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/data/font-sizes"
        }}>{`\`/api/data/font-sizes`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      